import React from 'react';
import { Link } from 'react-router-dom';
import ThemeIcon from '../ThemeIcon/ThemeIcon';
import './Service.scss';
import CountUp from 'react-countup';

const Service = ({serviceList}) => {
    const {title,description, icon,count} = serviceList;

    return (
        <div className='col-lg-4 col-md-4 col-sm-6'>
           <div className="service-box">
                <div className="service-icon">
                    <div className='icon-area'>
                    <ThemeIcon icon={icon} />
                        <CountUp start={0} end={count} delay={2}>
                            {({ countUpRef }) => (
                                <div className="service-text" style={{marginTop:15}}>
                                    <h2><span ref={countUpRef} /></h2>
                                </div>
                            )}
                        </CountUp>
                    </div>
                </div>
                <div className="service-text">
                    <h3><Link to="/singleservice">{title}</Link></h3>
                    {/*<p>{description}</p>*/}
                </div>
           </div>
        </div>
    );
};

export default Service;
