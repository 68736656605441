import iconOne from '../../assets/service/1.png';
import iconTwo from '../../assets/service/2.png';
import iconThree from '../../assets/service/3.png';
import iconFour from '../../assets/service/4.png';
import iconFive from '../../assets/service/5.png';
import iconSix from '../../assets/service/6.png';

const featuresData = [
    {
        'icon': iconOne,
        'title': 'CAD/CAM',
        'description': 'Precision Beyond Measure: Crafting Smiles with CAD/CAM Innovation',

    },
    {
        'icon': iconTwo,
        'title': '3D Printing',
        'description': 'Sculpting Smiles in Three Dimensions: Precision, Innovation, Perfection.',
    },
    {
        'icon': iconThree,
        'title': 'Crown',
        'description': 'Royal Smiles Begin Here: Crowned with Precision, Crafted with Care.',
    },
    {
        'icon': iconFour,
        'title': 'Bridge',
        'description': 'Bridging Joy, Restoring Harmony: Where Smiles Connect with Precision.',
    },
    {
        'icon': iconFive,
        'title': 'Not removable prosthesis',
        'description': 'Smile Stronger: Precision Prosthetics, Lasting Confidence',
    },
    {
        'icon': iconSix,
        'title': 'Removable prosthesis',
        'description': 'Revitalize Your Smile, Unleash Confidence: Removable Prosthesis, Endless Comfort.',
    }
]

export default featuresData;
