import React from 'react';
import './SingleBlog.scss';
import Navbar from '../../components/Navbar/Navbar';
import { Link, useParams } from 'react-router-dom';
import BlogData from '../../sections/Blogs/BlogData';
import Footer from '../../sections/Footer/Footer';

const SingleBlog = () => {

    const {url} = useParams();
    const blog = BlogData.find(blog => blog.url === url);
    const {title, img} = blog;

    return (
        <>
            <Navbar />
            <section className='section-bg pb-70 single-blog'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <main className="single-blog-area">
                                <p className="single-blog-category">
                                    TECHNOLOGY
                                </p>
                                <h1 className="single-blog-title">{title}</h1>
                                <img className='single-blog-banner' src={img} alt="blog banner"/>
                                <h2>Welcome to UnityDent's Monthly Newsletter!</h2>
                                <br/>
                                <p>Greetings from UnityDent! We hope this newsletter finds
                                    you well and thriving. As we continue to innovate and expand our offerings, we’re
                                    excited to share the latest updates and insights from our laboratory. This month,
                                    we’re focusing on our cutting-edge dental CAD services and the incredible benefits
                                    of using Exocad as a freelancer.</p>
                                <p>At UnityDent, we pride ourselves on staying at the
                                    forefront of dental technology. Our freelance dental CAD services are designed to
                                    provide you with the highest level of precision and efficiency. Whether you’re a
                                    dentist, orthodontist, or a dental lab technician, our services can help you achieve
                                    exceptional results.</p>
                                <blockquote className='single-post-quote'>Our team of experienced professionals is
                                    skilled in utilizing the latest dental CAD software to create precise and customized
                                    dental restorations. By leveraging our freelance services, you can enjoy the
                                    flexibility of working with experts without the need for a full-time commitment.
                                </blockquote>
                                <br/>
                                <h4>Why Choose UnityDent for Dental CAD Services?</h4>
                                <br/>
                                <ul>
                                    <li ><b>Expertise and Precision:</b> Our team has extensive experience in dental CAD
                                        design, ensuring accurate and high-quality restorations.
                                    </li>
                                    <li><b>Flexibility:</b> Our freelance model allows you to access our services as needed, making it a cost-effective solution for your practice.</li>
                                    <li><b>Advanced Technology:</b> We use the latest software and tools to deliver top-notch results.</li>
                                </ul>
                                <h4>Exocad: Revolutionizing Dental CAD Services</h4>
                                <br/>
                                <p>We are thrilled to announce our partnership with Exocad, one of the leading dental CAD software solutions. Exocad’s powerful tools and intuitive interface make it easier than ever to design and produce dental restorations with precision and speed.</p>
                                <h5>Benefits of Using Exocad:</h5>
                                <br/>
                                <ul>
                                    <li><b>User-Friendly Interface:</b> Exocad is known for its easy-to-use interface, making it accessible for both beginners and experienced users.</li>
                                    <li><b>Versatility:</b> The software supports a wide range of dental applications, from simple crowns to complex implant restorations.</li>
                                    <li><b>Efficiency:</b> Exocad streamlines the design process, reducing turnaround times and improving productivity.</li>
                                </ul>
                                <p>By integrating Exocad into our workflow, we can offer our clients even more accurate and efficient dental CAD services. Whether you need single-tooth restorations or full-mouth reconstructions, Exocad provides the tools necessary to achieve optimal results.</p>
                            </main>
                        </div>
                        <div className="col-lg-3">
                            <div className="blog-sidebar">
                                <div className="sidebar-subscribe mb-5">
                                    <h3 className="sidebar-heading">Get Latest Updates</h3>
                                    <form>
                                        <div className="form-group">
                                            <input type="email" className='form-control' placeholder='Subscribe to email'/>
                                            <input className='btn btn-primary mt-1 w-100' type="button" value="Subscribe" />
                                        </div>
                                    </form>
                                </div>

                                <div className="sidebar-category mb-3">
                                    <h3 className="sidebar-heading">Categories</h3>
                                    <ul>
                                        <li><Link href="/">Technology</Link></li>
                                        <li><Link href="/">Root Canal</Link></li>
                                        <li><Link href="/">Teeth</Link></li>
                                        <li><Link href="/">Whitening</Link></li>
                                        <li><Link href="/">Dentures</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default SingleBlog;
